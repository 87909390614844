// SupabaseContext.tsx
import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { supabase } from '../supabase';
import { Session } from '@supabase/supabase-js';
import { jwtDecode } from 'jwt-decode';

interface DecodedToken {
  user_role?: string;
  [key: string]: any;
}

interface SupabaseContextProps {
  session: Session | null;
  userRole: string | null;
  displayName: string | null;
  supabase: typeof supabase;
}

const SupabaseContext = createContext<SupabaseContextProps | undefined>(undefined);

interface SupabaseProviderProps {
  children: ReactNode;
}

export const SupabaseProvider: React.FC<SupabaseProviderProps> = ({ children }) => {
  const [session, setSession] = useState<Session | null>(null);
  const [userRole, setUserRole] = useState<string | null>(null);
  const [displayName, setDisplayName] = useState<string | null>(null);

  useEffect(() => {
    // Get the initial session state
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      if (session) {
        const decodedToken: DecodedToken = jwtDecode(session.access_token);
        setUserRole(decodedToken.user_role || null);
        setDisplayName(decodedToken.display_name || null);
      }
    });

    // Set up an auth state change listener
    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      if (session) {
        const decodedToken: DecodedToken = jwtDecode(session.access_token);
        setUserRole(decodedToken.user_role || null);
      }
    });

    // Clean up the subscription on unmount
    return () => {
      subscription.unsubscribe();
    };
  }, []); // Empty dependency array

  return (
    <SupabaseContext.Provider value={{ session, userRole, supabase, displayName }}>
      {children}
    </SupabaseContext.Provider>
  );
};

export const useSupabase = (): SupabaseContextProps => {
  const context = useContext(SupabaseContext);
  if (!context) {
    throw new Error('useSupabase must be used within a SupabaseProvider');
  }
  return context;
};