import CommentCardInterface from "../interfaces/CommentCard";

import ReactTimeAgo from 'react-time-ago'


function CommentCard({ comment }: { comment: CommentCardInterface}) {

    return (
    <div className='container mx-auto dark:text-gray-200 mt-4'>
      <div className='text-sm flex flex-row mt-2'>
    
        <div className='flex'>
        <p className="dark:text-white font-bold">{comment.user.name}</p>
        </div>
        <div className='flex ml-4'>
          <p className='text-right'> <ReactTimeAgo date={comment.created} locale="en-US"/></p>
        </div>
      </div>
      <div>
      <p className='text-base pl-4'>{comment.comment}</p>
      </div>
      
    </div>
    )
}

export default CommentCard;