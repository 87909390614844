import SeveritySelect from "./SeveritySelect";
import WheelSelect from "./WheelSelect";
import { Formik, Field, Form, FormikHelpers } from 'formik';
import ProblemFormValues from "../../interfaces/ProblemFormValue";
import * as Yup from 'yup';
import { Tables } from '../../interfaces/database';

import FileInput from "./FileInput";
import { useSupabase } from "../../contexts/Subabase";
import { useCallback, useEffect, useState } from "react";


interface ProblemFormProps {
    initialValues: ProblemFormValues;
    onSubmit: (values: ProblemFormValues, { setSubmitting, resetForm }: FormikHelpers<ProblemFormValues>) => Promise<void>;
  }

export default function ProblemForm({ initialValues, onSubmit }: ProblemFormProps) {

    const { supabase } = useSupabase();

    const [components, setComponents] = useState<Tables<'component'>[]>([]);
    const [loading, setLoading] = useState(true);

    const getComponents = useCallback(async () => {
        const { data, error } = await supabase
          .from('component')
          .select('*').order('name');
    
        if (error) {
          console.error('Error fetching components:', error);
        } else {
          setComponents(data || []);
          setLoading(false);
        }
      }, [supabase]);
    
    useEffect(() => {
        getComponents();
    }, [getComponents]);

    const validationSchema = Yup.object().shape({
        title: Yup.string().required('Title is required'),
        description: Yup.string().required('Description is required'),
        files: Yup.mixed().nullable().test('fileSize', 'File too large', (value) => {
            if (!value || !(value instanceof FileList)) return true;
            for (let i = 0; i < value.length; i++) {
            if (value[i].size > 20 * 1024 * 1024) {
                return false;
            }
            }
            return true;
        })
         });

    console.log('initialValues', initialValues);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}>
            {({ errors, touched, isSubmitting, values }) => (
            <Form>
              <div className="p-4">
                <WheelSelect defaultValue={initialValues.selectedWheel} />
                <label htmlFor="title" className="text-sm text-gray-500 ms-3 dark:text-neutral-400">Title*:</label>
                <div className="max-w space-y-3 border border-gray-200 rounded-lg">
                  <Field name='title' id='title' className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600" placeholder="Problem title" />
                </div>
                {errors.title && touched.title ? (
                  <p className="text-sm ml-2 text-red-500">{errors.title}</p>
                ) : null}

                <div className="max-w mt-4">
                  <label htmlFor="title" className="text-sm mt-4 text-gray-500 ms-3 dark:text-neutral-400">What happen?*:</label>
                  <Field as='textarea' name='description' className="py-3 px-4 block w-full border border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600" rows={3} placeholder="Describe and stay factual" />
                </div>
                
                {errors.description && touched.description ? (
                  <p className="text-sm ml-2 text-red-500">{errors.description}</p>
                ) : null}

                <SeveritySelect />

                <p className="mt-4 text-sm text-gray-500 ms-3 dark:text-neutral-400">Affected components:</p>
                <div className="chip-container mt-4">
                  {components.map(comp => (
                    <label className="text-sm" key={`label-${comp.id}`} >
                      <Field type="checkbox" name="affectedComponents" value={`${comp.id}`} />
                      {`${comp.name}`}
                    </label>
                  ))}
                </div>

                <FileInput />
                {errors.files && touched.files ? (
                  <p className="text-sm ml-2 text-red-500">{errors.files}</p>
                ) : null}

                <div className="flex justify-end items-center gap-x-2 py-3 px-4 border-t dark:border-neutral-700">
                  <button type="button" className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800" data-hs-overlay="#hs-basic-modal">
                  Cancel
                  </button>
                  <button type="submit" className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                    disabled={isSubmitting}
                  >
                  Save changes
                  </button>
                </div>
              </div>
            </Form>
            )}
          </Formik>
    )
}
