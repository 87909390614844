import { useState } from 'react';
import { supabase } from '../../supabase';
import { redirect } from 'react-router-dom'
import { Turnstile } from '@marsidev/react-turnstile'

export default function SignUp() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [captchaToken, setCaptchaToken] = useState<string | undefined>(undefined);


  async function signUpWithEmail() {
    console.log('signing up')
    console.log(captchaToken)
    const { error } = await supabase.auth.signUp({
        email: email,
        password: password,
        options: {
          data: {
            display_name: displayName,
          },
          captchaToken: captchaToken,
          emailRedirectTo: `${window.location.origin}/login?welcome=true`,
        },
      })

    if (error) {
      console.error('Error signing up:', error.message);
    } else {
        return redirect('/verify')
    }
    }


  return (
    <div className="flex justify-center">
          <div className="flex flex-col w-90 h-content bg-white border border-gray-200 shadow-sm rounded-xl p-4 md:p-5 dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400">
          <h1 className='font-bold text-gray-600'>Create Account</h1>

          <input 
        type="text" 
        placeholder="Display Name" 
        value={displayName} 
        onChange={(e) => setDisplayName(e.target.value)} 
        className="mt-4 py-3 ps-4 pe-10 block w-full border border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"

      />
      <input 
        type="email" 
        placeholder="Email" 
        value={email} 
        onChange={(e) => setEmail(e.target.value)} 
        className="mt-4 py-3 px-4 block w-full border border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"

      />
      <input 
        type="password" 
        placeholder="Password" 
        value={password} 
        onChange={(e) => setPassword(e.target.value)} 
        className="mt-4 py-3 ps-4 pe-10 block w-full border border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"

      />
      <Turnstile
        className='mt-4'
        siteKey="0x4AAAAAAAgUYxdCujQP7BsD"
        onSuccess={(token) => {
          setCaptchaToken(token)
        }}
        options={{
          action: 'register',
          theme: 'light',
        }}
      />  
      <button className=" mt-4 py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none" onClick={signUpWithEmail}><p className='text-center w-full'>Sign up</p></button>
    </div>
    </div>
  );
}