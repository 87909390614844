import { useCallback, useEffect, useState } from 'react';
import { useSupabase } from '../contexts/Subabase';
import CommentCard from './CommentCard';
import {  QueryData } from '@supabase/supabase-js'
import CommentCardInterface from '../interfaces/CommentCard';
import CommentFormComponent from './CommentForm';


function CommentListComponent({ problemId }: { problemId: string | undefined }) {
    const { supabase } = useSupabase();
    const [comments, setComments] = useState<any[]>([]);

    const fetchComments = useCallback(async () => {
        if (problemId === undefined) {
            return false
          }

        const CommentWithProfileQuery =  supabase
            .from('comment')
            .select('*,  profile!inner(id,  display_name)')
            .eq('problem_id', problemId || '').order('created', { ascending: true });

        type CommentWithProfile = QueryData<typeof CommentWithProfileQuery>

        const { data, error } = await CommentWithProfileQuery;
        if (error) {
            console.error('Error fetching comments: ' + error.message);
            return false;
        } 
           
        const comments: CommentWithProfile = data;
        const newComments: CommentCardInterface[] = [];
        comments.forEach((comment) => {
            const cardComment: CommentCardInterface = {
                id: comment.id,
                comment: comment.comment,
                created: comment.created ? new Date(comment.created) : new Date(),
                user: {
                    name: comment.profile.display_name ?? 'anon',
                    id: comment.profile.id
                }
            };
            newComments.push(cardComment);
        });
        setComments(newComments);

    }, [problemId, supabase]);

    useEffect(() => {
        fetchComments();
    }, [fetchComments]);

    

    return (
        <>
        <CommentFormComponent problemId={problemId} />
        <div>
            {comments.map((comment) => (
                <CommentCard key={comment.id} comment={comment} />
            ))}
        </div>
        </>
    );
}
export default CommentListComponent;