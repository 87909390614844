import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { supabase } from '../../supabase';
 
const Oauth = () => {
  const location = useLocation()
  const navigate = useNavigate()


 
  React.useEffect(() => {
    const exchangeTokenForUser = async () => {
      const params = new URLSearchParams(location.hash.slice(1))
      const accessToken = params.get('access_token')
      const refreshToken = params.get('refresh_token')
 
      try {
        if (accessToken) {
          //const user = await exchange(accessToken)

            const { error } = await supabase.auth.setSession({
              access_token: accessToken,
              refresh_token: refreshToken ?? '',
            })

            if (error) {
                console.error('error logging in:', error)
                navigate('/login')
                return
                }

 
          navigate('/')
          console.info('Logged in successfully!', 'success')
        } else {
          navigate('/login')
          console.error('something went wrong! Try again.', 'error')
        }
      } catch (error) {
        console.error(`${error}`, 'error')
      }
    }
 
    exchangeTokenForUser()
  }, [location.hash, navigate])
 
  return (
    <>
      <p>Please wait, while we take you to your dashboard...</p>
    </>
  )
}

export default Oauth;