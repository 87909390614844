
import { createClient } from "@supabase/supabase-js";
import { Database } from "./interfaces/database";


export const supabase = createClient<Database>("https://pvxxzazvwhwpoyuuvkyc.supabase.co", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InB2eHh6YXp2d2h3cG95dXV2a3ljIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTc0MDg4ODksImV4cCI6MjAzMjk4NDg4OX0.074OIFMi1VPZtdvUoezcHfqLB1DKaPHXQ6d0qNp-2Lw",{
    auth: {
      autoRefreshToken: true,
      persistSession: true,
      detectSessionInUrl: false,
    },
  });
