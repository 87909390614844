import { useState, useCallback, useEffect } from "react";

import GetProblems from "../../queries/Problem";
import ProblemCardInterface from '../../interfaces/ProblemCard';
import ProblemCard from "../../components/ProblemCard";
import { useSupabase } from "../../contexts/Subabase";


function ProblemGrid() {


    const [problems, setProblems] = useState<ProblemCardInterface[]>([])
    const { supabase } = useSupabase();

    const getProblems = useCallback(async () => {
        const { data, error } = await GetProblems({ limit: 4, supabase });
        if (error) {
            console.error('Error fetching problem:', error);
        } else {
            setProblems(data || []);
        }
    }, [supabase]);

    useEffect(() => {
        getProblems();
    }, [getProblems]);

    return (
        <div className='max-w-[60rem] mx-auto'>
            <div className="grid gap-4 md:grid-cols-2 sm:grid-cols-1">
            {problems.map(problem => (
            <ProblemCard key={problem.id} problem={problem} />
            ))}
            </div>
      </div>
    );
}

export default ProblemGrid;