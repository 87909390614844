import { useFormikContext, FormikValues } from 'formik';

const FileInput = () => {
  const { setFieldValue } = useFormikContext<FormikValues>();

  return (
    <div className="max-w-sm mt-4">
      <p className="mt-4 text-sm text-gray-500 ms-3 dark:text-neutral-400">
        Attach files (images only, max 20MB)
      </p>
      <input
        id="files"
        name="files"
        type="file"
        multiple
        onChange={(event) => {
          if (event.currentTarget.files) {
            setFieldValue('files', event.currentTarget.files);
          }
        }}
        className="py-3 px-4 text-gray-500 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
      />
    </div>
  );
};


export default FileInput;