
import { useEffect, useState, useCallback } from "react";
import WheelCard from '../../components/WheelCard';
import { useSupabase } from '../../contexts/Subabase';
import Wheel from "../../interfaces/Wheel";
import { Link } from "react-router-dom";

function Wheels() {
  const { supabase, session } = useSupabase();
  const [wheels, setWheels] = useState<Wheel[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const getWheels = useCallback(async () => {
    const { data, error } = await supabase
      .from('wheel_view')
      .select(`
        name,
        slug,
        image,
        brand,
        total_problem
        `).order('brand', { ascending: true }).order('name', { ascending: true });

    if (error) {
      console.error('Error fetching wheels:', error);
    } else {
      const wheelsWithBrands = data.map((wheel: any) => ({
        ...wheel
      }));
      setWheels(wheelsWithBrands || []);
    }
    setLoading(false);
  }, [supabase]);

  useEffect(() => {
    getWheels();
  }, [getWheels]);

  if (loading) {
    return <div>Loading...</div>;
  }

  
  return (
    <>
    <div className='max-w-[85rem] w-full mx-auto flex flex-wrap'>
      {wheels.map(wheel => (
        <WheelCard key={wheel.slug} wheel={wheel} />
      ))}
    </div>
  
      { session && (
        <div className="fixed bottom-4 right-4 z-50">
        <Link to="/contact">
        <button type="button" className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none">
        Missing wheel ?
        </button>
        </Link>
      </div>
)}
      
      </>
  );
}

export default Wheels;
