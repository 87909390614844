import { useParams } from 'react-router-dom'
import NewProblemForm from './NewProblem';
import { useState, useCallback, useEffect } from "react";
import ProblemCardInterface from '../../interfaces/ProblemCard';
import ProblemCard from '../../components/ProblemCard';
import GetProblems from '../../queries/Problem';
import { useSupabase } from '../../contexts/Subabase';

function ProblemByWheel() {

  const { slug } = useParams()
  const { supabase } = useSupabase();
  const [problems, setProblems] = useState<ProblemCardInterface[]>([])

  const getProblems = useCallback(async () => {
    const { data, error } = await GetProblems({ limit: 100, wheelSlug: slug, supabase });
    if (error) {
        console.error('Error fetching problem:', error);
    } else {
        setProblems(data || []);
    }
    return undefined;
  }, [slug, supabase]);

  useEffect(() => {
    getProblems();
  }, [getProblems]);


  return (
    <div>
      <NewProblemForm wheelSlug={slug ?? ''} refresh={getProblems} />
      <div className='container max-w-2xl'>
        {problems.map(problem => (
          <ProblemCard key={problem.id} problem={problem} />
        ))}
      </div>
    </div>
  );
}

export default ProblemByWheel;
