import { useSupabase } from '../../contexts/Subabase';
import { useEffect, useState, useCallback } from "react";
import { Tables } from '../../interfaces/database';
import { Field } from 'formik';


function SeveritySelect() {
    const { supabase } = useSupabase();
    const [severities, setSeverities] = useState<Tables<'severity'>[]>([]);

    const getSeverities = useCallback(async () => {
        const { data, error } = await supabase
          .from('severity')
          .select(`
            *
          `).order('score');
    
        if (error) {
          console.error('Error fetching severities:', error);
        } else {
          
          setSeverities(data || []);
        }
      }, [supabase]);
    
      useEffect(() => {
        getSeverities();
      }, [getSeverities]);


    return (
<div className="relative">
  <Field as="select" className="peer bg-white border mt-4 p-4 pe-9 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:focus:ring-neutral-600
  focus:pt-6
  focus:pb-2
  [&:not(:placeholder-shown)]:pt-6
  [&:not(:placeholder-shown)]:pb-2
  autofill:pt-6
  autofill:pb-2"
  name="severity">
            { severities.map(severity => (
            <option key={severity.id} value={severity.id} >{severity.name}: {severity.example}</option>
        ))}
        </Field>
        <label className="absolute top-0 start-0 p-4 h-full truncate pointer-events-none transition ease-in-out duration-100 border border-transparent dark:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none
    peer-focus:text-xs
    peer-focus:-translate-y-1.5
    peer-focus:text-gray-500 dark:peer-focus:text-neutral-500
    peer-[:not(:placeholder-shown)]:text-xs
    peer-[:not(:placeholder-shown)]:-translate-y-1.5
    peer-[:not(:placeholder-shown)]:text-gray-500 dark:peer-[:not(:placeholder-shown)]:text-neutral-500 dark:text-neutral-500">Severity</label>
</div>
  );
}

export default SeveritySelect;