import { useState, useCallback, useEffect } from "react";

import GetProblems from "../../queries/Problem";
import ProblemCardInterface from '../../interfaces/ProblemCard';
import ProblemCard from "../../components/ProblemCard";
import { useSupabase } from "../../contexts/Subabase";


function Problem() {


    const [problems, setProblems] = useState<ProblemCardInterface[]>([])
    const { supabase } = useSupabase();

    const getProblems = useCallback(async () => {
        const { data, error } = await GetProblems({ limit: 100, supabase });
        if (error) {
            console.error('Error fetching problem:', error);
        } else {
            setProblems(data || []);
        }
    }, [supabase]);

    useEffect(() => {
        getProblems();
    }, [getProblems]);
    

    return (
        
        <div className='container max-w-2xl'>
            {problems.map(problem => (
            <ProblemCard key={problem.id} problem={problem} />
            ))}
            </div>

    );
}




export default Problem;