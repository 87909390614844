import { Link } from "react-router-dom";

export default function Privacy() {
    return (
        <div className="container mx-auto p-8">
        <div className="bg-white shadow-lg rounded-lg p-6">
        <h1 className="text-3xl font-bold text-gray-900 mb-4">Privacy Policy</h1>
        <p className="text-sm text-gray-500 mb-8">Last Updated: <span>August 2024</span></p>

        <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">1. Information We Collect</h2>
            <h3 className="text-xl font-semibold text-gray-700 mb-2">a. Personal Information</h3>
            <p className="mb-4">We may collect personal information that you voluntarily provide when you register for an
                account, use our services, or interact with the app. This information may include:</p>
            <ul className="list-disc list-inside ml-6 mb-4">
                <li>Name</li>
                <li>Email address</li>
                <li>Contact information</li>
            </ul>

            <h3 className="text-xl font-semibold text-gray-700 mb-2">b. Non-Personal Information</h3>
            <p className="mb-4">We may also collect non-personal information automatically when you use our app. This
                includes:</p>
            <ul className="list-disc list-inside ml-6 mb-4">
                <li>Device information (e.g., device type, operating system)</li>
                <li>Usage data (e.g., app interactions, features used)</li>
                <li>Log information (e.g., IP address, access times)</li>
            </ul>

            <h3 className="text-xl font-semibold text-gray-700 mb-2">c. Cookies and Tracking Technologies</h3>
            <p className="mb-4">We may use cookies and similar tracking technologies to collect information about your
                interaction with our app. This helps us to improve your experience and analyze usage patterns.</p>
        </section>

        <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">2. How We Use Your Information</h2>
            <p className="mb-4">We may use the information we collect for various purposes, including:</p>
            <ul className="list-disc list-inside ml-6 mb-4">
                <li><span className="font-semibold">Providing and improving services:</span> To personalize your experience,
                    develop new features, and maintain and enhance the app’s functionality.</li>
                <li><span className="font-semibold">Communication:</span> To send you updates, newsletters, and promotional
                    materials related to our services, subject to your preferences.</li>
                <li><span className="font-semibold">Customer support:</span> To respond to your inquiries and resolve any
                    issues you may have.</li>
                <li><span className="font-semibold">Security:</span> To monitor and ensure the security of the app and
                    protect against fraud and misuse.</li>
                <li><span className="font-semibold">Compliance:</span> To comply with legal obligations and enforce our terms
                    and policies.</li>
            </ul>
        </section>

        <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">3. How We Share Your Information</h2>
            <p className="mb-4">We may share your information in the following situations:</p>
            <ul className="list-disc list-inside ml-6 mb-4">
                <li><span className="font-semibold">With Other Users:</span> If you post reviews or comments, these may be
                    visible to other users of the app.</li>
                <li><span className="font-semibold">Legal Requirements:</span> We may disclose your information if required
                    by law or in response to legal processes (e.g., a subpoena) or to protect the rights, property, and
                    safety of our users and others.</li>
            </ul>
        </section>

        <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">4. Your Choices and Rights</h2>
            <h3 className="text-xl font-semibold text-gray-700 mb-2">a. Account Information</h3>
            <p className="mb-4">You may review, update, or delete your account information at any time by logging into your
                account settings. If you choose to delete your account, we will retain your data only as necessary to
                comply with legal obligations.</p>

            <h3 className="text-xl font-semibold text-gray-700 mb-2">c. Data Access and Correction</h3>
            <p className="mb-4">You have the right to request access to the personal information we hold about you and to
                request corrections or deletions of any inaccurate data.</p>
        </section>

        <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">5. Security of Your Information</h2>
            <p className="mb-4">We use administrative, technical, and physical security measures to protect your personal
                information. However, no system can be completely secure, and we cannot guarantee the absolute security
                of your information.</p>
        </section>

        <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">6. Children’s Privacy</h2>
            <p className="mb-4">Wheel Wise is not intended for use by children under the age of 13. We do not knowingly
                collect personal information from children under 13. If we learn that we have collected such
                information, we will take steps to delete it as soon as possible.</p>
        </section>

        <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">7. Changes to This Privacy Policy</h2>
            <p className="mb-4">We may update this Privacy Policy from time to time. We will notify you of any changes by
                updating the “Last Updated” date at the top of this page and, in some cases, provide additional notice
                (such as adding a statement to our homepage or sending you a notification). We encourage you to review
                this Privacy Policy periodically to stay informed about how we are protecting your information.</p>
        </section>

        <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">8. Contact Us</h2>
            <p className="mb-4">If you have any questions or concerns about this Privacy Policy or our data practices, please
                use the <Link className="text-blue-800" to={'/contact'}>contact page </Link></p>
            
        </section>
        </div>
    </div>

    );
}