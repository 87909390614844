
import { useSupabase } from '../../contexts/Subabase';
import { Tables } from '../../interfaces/database';
import { v4 as uuidv4 } from 'uuid';
import HSOverlay from "@preline/overlay";
import { FormikHelpers } from 'formik';
import ProblemFormValues from "../../interfaces/ProblemFormValue";

import ProblemForm from "./ProblemForm";



// TODO: set default values for wheel
// TODO: success message
// TODO: error message: empty description, no components selected, supabase error

interface NewProblemFormProps {
  wheelSlug: string;
  refresh: () => Promise<false | undefined>;
}





function NewProblemForm({ wheelSlug, refresh }: NewProblemFormProps) {
  const { session, supabase } = useSupabase();


  const initialValues: ProblemFormValues = {
    selectedWheel: wheelSlug,
    affectedComponents: [],
    severity: 1,
    description: '',
    title: '',
    files: null,
    date: null,
  };
  

  if (!session){
    return(
      <></>
    )
  }

  const onSubmit= async (
    values: ProblemFormValues,
    { setSubmitting, resetForm }: FormikHelpers<ProblemFormValues>
  ) => {

    setSubmitting(true);

    const formData: Tables<'problem'> = {
      created: new Date().toISOString() ,
      date: null,
      deleted: null,
      id: uuidv4(),
      title: values.title,
      description: values.description,
      votes: 0,
      severity_id: values.severity,
      user_id: session.user.id,
      wheel_id: parseInt(values.selectedWheel?.toString() || '0'),
    };



    // Upload files to Supabase
    const uploadedFiles = [];
    if (values.files) {
      for (let i = 0; i < values.files.length; i++) {
        const file = values.files[i];
        console.log(file);
        if (file.type.startsWith('image/')) {
          const id = uuidv4();

          const { error } = await supabase.storage
            .from('wheelwise')
            .upload(`uploads/${formData.id}/${file.name}`, file);
      
          if (error) {
            console.error('Error uploading file:', error);
            return;
          }
      
          uploadedFiles.push({'id':id, 'name': file.name, 'size': file.size, 'type': file.type});
        }
      }
    }
  
    // Insert data into the "problem" table using Supabase

    

    const { error: saveError } = await supabase
      .from('problem')
      .insert([
        formData
      ]);
  
    if (saveError) {
      console.error('Error saving problem: ' + saveError.message);
    } else {
      console.debug('Problem saved successfully!');
    }
    const { error: componentError } = await supabase.from('problem_component').insert(values.affectedComponents.map(component_id => ({
      problem_id: formData.id,
      component_id: Number(component_id),
      user_id: session.user.id,
    })));
  
    if (componentError) {

      console.error('Error saving problem components: ' + componentError.message);
      // Delete previously created problem
      const { error: deleteError } = await supabase
        .from('problem')
        .delete()
        .eq('id', formData.id);

      if (deleteError) {
        console.error('Error deleting problem: ' + deleteError.message);
      } else {
        console.debug('Problem deleted successfully!');
      }
    } else {
      console.debug('Problem components saved successfully!');
    }

    // Insert files into the "problem_upload" table using Supabase
    for (let i = 0; i < uploadedFiles.length; i++) {
      const file = uploadedFiles[i];
      if (file.type.startsWith('image/')) {
        const fileData: Tables<'problem_upload'> = {
      id: file.id,
      problem_id: formData.id,
      user_id: session.user.id,
      path: `uploads/${formData.id}/${file.name}`,
      created: new Date().toISOString(),
      mimetype: file.type,
      original_name: file.name,
      size: file.size,
        }
        
        const { error: uploadError } = await supabase
      .from('problem_upload')
      .insert(fileData);
      
        if (uploadError) {
      console.error('Error saving problem upload: ' + uploadError.message);
        } else {
      console.debug('Problem upload saved successfully!');
        }
      } else {
        console.error('Invalid file type: ' + file.type);
      }
    }

    refresh();
    resetForm();
    setSubmitting(false);
    const modalElement = document.querySelector('#hs-basic-modal');
    if (modalElement instanceof HTMLElement) {
      HSOverlay.close(modalElement);
    }
    
  }


  return (
    <>
    <div className="fixed bottom-4 right-4 z-50">
      <button type="button" className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none" data-hs-overlay="#hs-basic-modal">
      New Problem
      </button>
    </div>
    <div id="hs-basic-modal" className="hs-overlay hs-overlay-open:opacity-100 hs-overlay-open:duration-500 hidden size-full fixed top-0 start-0 z-[80] opacity-0 overflow-x-hidden transition-all overflow-y-auto pointer-events-none">
        <div className="sm:max-w-lg sm:w-full m-3 sm:mx-auto">
        <div className="flex flex-col bg-white border shadow-sm rounded-xl mt-209 pointer-events-auto dark:bg-neutral-800 dark:border-neutral-700 dark:shadow-neutral-700/70">
          <div className="flex justify-between items-center py-3 px-4 border-b dark:border-neutral-700">
          <h3 className="font-bold text-gray-800 dark:text-white">
            New Problem
          </h3>
          <button type="button" className="flex justify-center items-center size-7 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-neutral-700" data-hs-overlay="#hs-basic-modal">
            <span className="sr-only">Close</span>
            <svg className="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M18 6 6 18"></path>
            <path d="m6 6 12 12"></path>
            </svg>
          </button>
          </div>
  
          <ProblemForm initialValues={initialValues} onSubmit={onSubmit} />
          
        </div>
        </div>
      </div>
    </>
    )
}

export default NewProblemForm;