import Wheel from "../interfaces/Wheel";

import { useEffect, useState } from "react";
import { useSupabase } from "../contexts/Subabase";
import { Link } from 'react-router-dom';

interface WheelCardProps {
    wheel: Wheel;
  }

function WheelCard({ wheel }: WheelCardProps) {

    const { supabase } = useSupabase();
    const [wheelImage, setWheelImage] = useState<string>('');

    useEffect(() => {
        const { data } = supabase.storage.from('wheelwise').getPublicUrl(wheel.image)
        setWheelImage( data.publicUrl )
      }, [wheel.image, supabase.storage]);
    return (
        <div className=" m-2 flex flex-col bg-white border shadow-sm rounded-xl dark:bg-neutral-900 dark:border-neutral-700 dark:shadow-neutral-700/70">
          <div className="h-full content-center">
          <img className="w-full max-w-60 h-auto rounded-t-xl" src={wheelImage} alt={wheel.name} />
          </div>
          <div className="p-4 md:p-5 h-full content-end">
              <p className="mt-1 text-gray-500 dark:text-neutral-400">
              {wheel.brand}
              </p>
              <h3 className="text-lg font-bold text-gray-800 dark:text-white">
              {wheel.name}
              </h3>
              <Link className="mt-2 relative py-2 px-3 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none" to={"/problems/bywheel/"+wheel.slug}>
              View Problems
              {wheel.total_problem > 0 &&
                <span className="absolute top-0 end-0 inline-flex items-center py-0.5 px-1.5 rounded-full text-xs font-medium transform -translate-y-1/2 translate-x-1/2 bg-red-500 text-white">{wheel.total_problem}</span>
              }
              </Link>
              
          </div>
        </div>       
    );
}

export default WheelCard