import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSupabase } from "../../contexts/Subabase";
import GetProblems from "../../queries/Problem";
import ProblemFormValues from "../../interfaces/ProblemFormValue";
import ProblemForm from "./ProblemForm";
import { FormikHelpers } from "formik";
import { useNavigate } from 'react-router-dom';




export default function EditProblem() {

  const { supabase, session } = useSupabase();
  const navigate = useNavigate();
  const { id } = useParams()
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState<ProblemFormValues>({} as ProblemFormValues);
  const [error, setError] = useState<boolean>(false);



  const fetchProblems = useCallback(async () => {
    const { data, error } = await GetProblems ({ limit: 1, id: id, supabase });
    if (error) {
        console.error('Error fetching problem:', error);
        setError(true);
    } else {
      if (data && data.length > 0) {
        setInitialValues(
          {
            selectedWheel: data[0].wheel.slug,
            affectedComponents: data[0].component.map((component: { id: any; }) => String(component.id)),
            severity: data[0].severity.id,
            description: data[0].description ?? '',
            title: data[0].title,
            files: null,
            date: null,
          }
        )
        setLoading(false);
      }
    }
  }, [id, supabase]);

  useEffect(() => {
    fetchProblems();
  }, [fetchProblems]);

  if (!id) {
    return <div>Invalid ID</div>;
  }

  const onSubmit = async (
    values: ProblemFormValues,
    { setSubmitting }: FormikHelpers<ProblemFormValues>
  ) => {
    console.log('values', values);
    setSubmitting(true);

    const formData = {
      title: values.title,
      description: values.description,
      wheel_id: Number(values.selectedWheel),
      severity_id: values.severity,
    };

    console.log('formData', formData);

    const { error } = await supabase.from('problem').update(formData).eq('id', id);
    if (error) {
      console.error('Error updating problem:', error);
      setError(true);
    }

    const { error: problemComponentsError, data: deleteData } = await supabase
      .from('problem_component')
      .delete()
      .eq('problem_id', id);

      console.log('deleteData', deleteData);

    if (problemComponentsError) {
      console.error('Error deleting problem components:', problemComponentsError);
      setError(true);
    }


    const uniqueAffectedComponents = Array.from(new Set(values.affectedComponents.map(Number)));

    const newProblemComponents = uniqueAffectedComponents.map((componentId: any) => ({
      problem_id: id,
      component_id: componentId,
      user_id: session?.user.id,
    }));

    console.log('newProblemComponents', newProblemComponents);

    const { error: insertError } = await supabase
      .from('problem_component')
      .insert(newProblemComponents);

    if (insertError) {
      console.error('Error inserting problem components:', insertError);
      setError(true);
    }

    setSubmitting(false);

    if (!error) {
      navigate(-1);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>

        <ProblemForm initialValues={initialValues} onSubmit={onSubmit} />
        <div className="w-80 mt-2 ml-4 bg-blue-600 text-sm text-white rounded-lg p-4 dark:bg-blue-500" role="alert" tabIndex={-1} aria-labelledby="hs-solid-color-info-label">
          Update of uploaded pictures will be available soon.
        </div>
        {error && <div>Error fetching problem</div>}
    </div>
  )
}