import { useParams } from 'react-router-dom'
import { useState, useCallback, useEffect } from "react";
import ProblemCardInterface from '../../interfaces/ProblemCard';
import ProblemCard from '../../components/ProblemCard';
import CommentListComponent from '../../components/CommentList';
import GetProblems from '../../queries/Problem';
import { useSupabase } from '../../contexts/Subabase';

function ProblemByID() {
  const { supabase } = useSupabase();
  const { id } = useParams()
  const [problems, setProblems] = useState<ProblemCardInterface[]>([])

  const fetchProblems = useCallback(async () => {
    const { data, error } = await GetProblems({ limit: 100, id: id, supabase });
    if (error) {
        console.error('Error fetching problem:', error);
    } else {
        setProblems(data || []);
    }
  }, [id, supabase]);

  useEffect(() => {
    fetchProblems();
  }, [fetchProblems]);
  

  return (
    <div>
      <div className='container max-w-2xl'>
        {problems.map(problem => (
          <ProblemCard key={problem.id} problem={problem} />
        ))}

        <CommentListComponent problemId={id} />
        
      </div>
    </div>
  );
}

export default ProblemByID;
