
import { useEffect, useState, useMemo } from 'react';
import { useSupabase } from '../contexts/Subabase';
import { Tables } from '../interfaces/database';
import { Swiper, SwiperSlide} from 'swiper/react';
import {  Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

function PictureCarousel({ uploads }: { uploads: Tables<'problem_upload'>[] }) {
  const { supabase } = useSupabase();
  const [wheelImage, setWheelImage] = useState<string[]>([]);
  // Memoize the supabase storage to ensure it doesn't change on every render
  const storage = useMemo(() => supabase.storage, [supabase]);

  useEffect(() => {
    const fetchImages = async () => {
      const images: string[] = [];
      for (const upload of uploads) {
        if (upload.path) {
          const { data } = await storage.from('wheelwise').getPublicUrl(upload.path);
          images.push(data.publicUrl);
        }
      }
      // Only update state if the images are different
      setWheelImage((prevImages) => {
        if (JSON.stringify(prevImages) !== JSON.stringify(images)) {
          return images;
        }
        return prevImages;
      });
    };

    fetchImages();
  }, [uploads, storage]);

  if (uploads.length === 0) {
    return <></>;
  }
  
  return (
    <Swiper
    modules={[Navigation, Pagination]}
      spaceBetween={50}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      rewind
      className='h-80'
    >
  
      {wheelImage.map((image, index) => (
        <SwiperSlide className='h-full' key={index}>
          <img src={image} alt="upload" className='h-full' />
          </SwiperSlide>
      ))}
    </Swiper>
  );
}

export default PictureCarousel;
