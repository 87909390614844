import { Link } from "react-router-dom";

export default function Terms() {
    return (
        <div className="container mx-auto p-8">
        <div className="bg-white shadow-lg rounded-lg p-6">
            <h1 className="text-3xl font-bold mb-6">Terms and Conditions</h1>
            <p className="text-gray-600 mb-6">Effective Date: <span className="font-medium">9 August 2004</span></p>

            <h2 className="text-2xl font-semibold mb-4">1. Acceptance of Terms</h2>
            <p className="mb-4">By accessing, browsing, or using Wheel Wise ("the Platform"), you acknowledge that you have read, understood, and agree to be bound by these Terms, along with our Privacy Policy. If you do not agree to these Terms, you should not use the Platform.</p>

            <h2 className="text-2xl font-semibold mb-4">2. Eligibility</h2>
            <p className="mb-4">You must be at least 18 years old to use the Platform. By using Wheel Wise, you represent and warrant that you are at least 18 years of age and have the legal capacity to enter into these Terms.</p>

            <h2 className="text-2xl font-semibold mb-4">3. Account Registration</h2>
            <ul className="list-disc list-inside space-y-2 mb-4">
                <li>Provide accurate, current, and complete information during the registration process.</li>
                <li>Maintain and promptly update your account information.</li>
                <li>Keep your password secure and confidential.</li>
                <li>Notify us immediately of any unauthorized use of your account.</li>
            </ul>

            <h2 className="text-2xl font-semibold mb-4">4. User Conduct</h2>
            <p className="mb-4">When using the Platform, you agree not to:</p>
            <ul className="list-disc list-inside space-y-2 mb-4">
                <li>Post or transmit any content that is unlawful, harmful, defamatory, obscene, or otherwise objectionable.</li>
                <li>Violate any applicable laws or regulations.</li>
                <li>Use the Platform for any fraudulent or malicious activities.</li>
                <li>Interfere with or disrupt the integrity or performance of the Platform.</li>
            </ul>

            <h2 className="text-2xl font-semibold mb-4">5. Content and Intellectual Property</h2>
            <h3 className="text-xl font-semibold mb-2">5.1 User-Generated Content</h3>
            <p className="mb-4">By submitting content to the Platform (e.g., reviews, comments, photos), you grant Wheel Wise a non-exclusive, royalty-free, worldwide license to use, reproduce, modify, and distribute your content in connection with the operation of the Platform. You retain ownership of any intellectual property rights that you hold in your content.</p>

            <h3 className="text-xl font-semibold mb-2">5.2 Platform Content</h3>
            <p className="mb-4">All content provided on the Platform, including text, graphics, logos, images, and software, is the property of Wheel Wise or its licensors and is protected by intellectual property laws. You may not reproduce, distribute, modify, or create derivative works of any content without our express written permission.</p>

            <h2 className="text-2xl font-semibold mb-4">6. Issue Registration and Reviews</h2>
            <p className="mb-4">The Platform allows users to report issues and provide reviews on specific EUC models. You agree that:</p>
            <ul className="list-disc list-inside space-y-2 mb-4">
                <li>All submissions are your own honest opinions and experiences.</li>
                <li>You will not post false, misleading, or defamatory content.</li>
                <li>Wheel Wise reserves the right to remove or modify any submissions that violate these Terms or are deemed inappropriate.</li>
            </ul>

            <h2 className="text-2xl font-semibold mb-4">7. Third-Party Links</h2>
            <p className="mb-4">The Platform may contain links to third-party websites or services. These links are provided for your convenience, and Wheel Wise does not endorse or assume any responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>

            <h2 className="text-2xl font-semibold mb-4">8. Disclaimers</h2>
            <p className="mb-4">The Platform is provided on an "as-is" and "as-available" basis. We do not warrant that the Platform will be uninterrupted, secure, or error-free. We make no warranties or representations about the accuracy or completeness of any content available on the Platform. Wheel Wise disclaims all liability for any direct, indirect, incidental, consequential, or punitive damages arising out of your use of the Platform.</p>

            <h2 className="text-2xl font-semibold mb-4">9. Limitation of Liability</h2>
            <p className="mb-4">To the fullest extent permitted by law, Wheel Wise shall not be liable for any damages resulting from your use or inability to use the Platform or any content therein.</p>

            <h2 className="text-2xl font-semibold mb-4">10. Indemnification</h2>
            <p className="mb-4">You agree to indemnify and hold harmless Wheel Wise, its affiliates, officers, directors, employees, and agents from any claims, damages, losses, liabilities, and expenses arising out of or related to your use of the Platform or violation of these Terms.</p>

            <h2 className="text-2xl font-semibold mb-4">11. Modifications to Terms</h2>
            <p className="mb-4">Wheel Wise reserves the right to modify these Terms at any time. We will notify you of any changes by posting the updated Terms on the Platform. Your continued use of the Platform after any such changes constitutes your acceptance of the new Terms.</p>

            <h2 className="text-2xl font-semibold mb-4">12. Governing Law</h2>
            <p className="mb-4">These Terms shall be governed by and construed in accordance with the laws of Belgium, without regard to its conflict of law principles.</p>

            <h2 className="text-2xl font-semibold mb-4">13. Termination</h2>
            <p className="mb-4">Wheel Wise reserves the right to terminate or suspend your account and access to the Platform at its sole discretion, without prior notice, for conduct that it believes violates these Terms or is harmful to other users or the Platform.</p>

            <h2 className="text-2xl font-semibold mb-4">14. Contact Us</h2>
            <p>If you have any questions or concerns about these Terms, please contact us on our <Link to="/contact" className="text-blue-600 hover:underline">Contact Page</Link>.</p>
        </div>
    </div>

    );
}