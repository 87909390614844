import { Navigate } from 'react-router-dom';
import { supabase } from '../../supabase'



const Logout = () => {
    supabase.auth.signOut()

    return <Navigate to="/" replace />;
}

export default Logout