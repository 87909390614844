import React from 'react';
import ProblemGrid from './ProblemGrid';

function Home() {
  return (
    <>
    <div className="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 mt-4">
      <div className="grid lg:grid-cols-7 lg:gap-x-8 xl:gap-x-12 lg:items-center">
        <div className="lg:col-span-4">
          <h1 className="block text-3xl font-bold text-gray-800 sm:text-3xl md:text-3xl lg:text-3xl dark:text-white">Ride Safer Together</h1>
          <p className="mt-3 text-lg text-gray-800 dark:text-neutral-400">We aim to track & report issues for electric unicycles. Report problems and play a vital role in creating a safer, more informed EUC community. Your feedback is essential for product enhancement and reliability.</p>
          <p className="mt-3 text-lg text-gray-800 dark:text-neutral-400">Your insights may drive manufacturers to continuously improve their products. We might be the beta-testers, but now we have a dedicated place to submit our failure reports and make a difference!</p>
    
          <div className="mt-5 lg:mt-8 flex flex-col items-center gap-2 sm:flex-row sm:gap-3">
            <div className="w-full sm:w-auto">
              </div>

          </div>
        </div>
        <div className="lg:col-span-3 mt-10 lg:mt-0">
          <img className="object-none object-left-top w-full rounded-xl h-80" src="/images/hero-v12.jpg" alt="Inmotion V12 wires" height={50} />
        </div>
      </div>
    <h2 className='text-3xl font-bold text-gray-800 dark:text-white'>Latest Problems</h2>
    </div>
    
    <ProblemGrid />
    </>
  );
}

export default Home;
