import React from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './App.css';
import Home from './pages/home'
import Wheels from './pages/wheels';
import Contact from './pages/contact'
import Login from './pages/auth/Login';
import Logout from './pages/auth/Logout';
import NotFound from './pages/errors'
import Navigation from './components/navigation';
import { Routes, Route } from 'react-router-dom';
import { SupabaseProvider } from './contexts/Subabase';

import "preline/preline";
import { IStaticMethods } from "preline/preline";
import ProblemByWheel from './pages/problem/ByWheel';
import SignUp from './pages/auth/SignUp';
import ProblemByID from './pages/problem/ByID';
import Oauth from './pages/auth/Oauth';
import Problem from './pages/problem';
import VerifyEmail from './pages/auth/VerifyEmail';
import Profile from './pages/profile';
import Terms from './pages/home/Terms';
import EditProblem from './pages/problem/Edit';
import Privacy from './pages/home/Privacy';


declare global {
  interface Window {
    HSStaticMethods: IStaticMethods;
  }
}

function App() {  
  const location = useLocation();

  useEffect(() => {
    window.HSStaticMethods.autoInit();
  }, [location.pathname]);

  return (
    <SupabaseProvider>
    <header className="flex flex-wrap sm:justify-start sm:flex-nowrap w-full text-sm py-4  dark:bg-neutral-800">
      <Navigation />
    </header>
      
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/wheels" element={<Wheels />} />
        <Route path="/problems" element={<Problem />} />
        <Route path="/problems/bywheel/:slug" element={<ProblemByWheel />} />
        <Route path="/problems/id/:id" element={<ProblemByID />} />
        <Route path="/problems/edit/:id" element={<EditProblem />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/login" element={<Login />} />
        <Route path="/user/profile" element={<Profile />} />
        <Route path="/oauth" element={<Oauth />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/verify" element={<VerifyEmail />} />
        <Route path="/termsandconditions" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      </SupabaseProvider>
  );
}

export default App;
