import { useSupabase } from '../../contexts/Subabase';
import { useEffect, useState, useCallback } from "react";
import { Tables } from '../../interfaces/database';
import { useFormikContext, Field } from 'formik';

interface WheelSelectProps {
  //onChange: (value: number) => void;
  defaultValue: string | number | null;
}

// Correctly destructure the `onChange` prop from the function component's props
function WheelSelect({ defaultValue }: WheelSelectProps) {
    const { supabase } = useSupabase();
    const [wheels, setWheel] = useState<Tables<'wheel'>[]>([]);
    const { setFieldValue } = useFormikContext();

    const getIdFromSlug = useCallback((slug: string | number): number | undefined => {
      if (typeof slug === 'number') return slug
      const wheel = wheels.find(wheel => wheel.slug === slug);
      return wheel?.id;
    }, [wheels]);
    
    useEffect(() => {
      setFieldValue('selectedWheel', getIdFromSlug(defaultValue ?? "") ?? 0);
    }, [defaultValue, getIdFromSlug, setFieldValue]);

    const getWheel = useCallback(async () => {
        const { data, error } = await supabase
          .from('wheel')
          .select(`
            *
          `).order('id_brand').order('name');
    
        if (error) {
          console.error('Error fetching wheel:', error);
        } else {
          setWheel(data || []);
        }
      }, [supabase]);
    
      useEffect(() => {
        getWheel();
      }, [getWheel]);

    // Use the `onChange` prop correctly in the <select> element
    return (
<div className="relative">
  <Field
    as="select" 
    name="selectedWheel"
    className="peer bg-white border mt-4 p-4 pe-9 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:focus:ring-neutral-600
    focus:pt-6
    focus:pb-2
    [&:not(:placeholder-shown)]:pt-6
    [&:not(:placeholder-shown)]:pb-2
    autofill:pt-6
    autofill:pb-2"
  >
    {wheels.map(wheel => (
      <option key={wheel.id} value={wheel.id}>{wheel.name}</option>
    ))}
  </Field>
  <label className="absolute top-0 start-0 p-4 h-full truncate pointer-events-none transition ease-in-out duration-100 border border-transparent dark:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none
  peer-focus:text-xs
  peer-focus:-translate-y-1.5
  peer-focus:text-gray-500 dark:peer-focus:text-neutral-500
  peer-[:not(:placeholder-shown)]:text-xs
  peer-[:not(:placeholder-shown)]:-translate-y-1.5
  peer-[:not(:placeholder-shown)]:text-gray-500 dark:peer-[:not(:placeholder-shown)]:text-neutral-500 dark:text-neutral-500">Wheel</label>
</div>
    );
}

export default WheelSelect;