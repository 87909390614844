import React from 'react';

interface SeverityProps {
    name: string | null;
    score: number | null;
}

function ComponentSeverity({ name,score }: SeverityProps) {
    let classContent = '';

    switch (score) {
        case 10:
            classContent = 'inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-gray-50 text-gray-500 dark:bg-white/10 dark:text-white';
            break;
        case 20:
            classContent = 'inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800 dark:bg-yellow-800/30 dark:text-yellow-500';
            break;
        case 30:
            classContent = 'inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-orange-100 text-orange-800 dark:bg-orange-800/30 dark:text-orange-500';
            break;
        case 40:
            classContent = 'inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-red-100 text-red-800 dark:bg-red-800/30 dark:text-red-500';
            break;
    }

    return (
        <span className={classContent}>
            {name}
        </span>

    );
};

export default ComponentSeverity;