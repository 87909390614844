import type ProblemCardInterface from '../interfaces/ProblemCard';
import ComponentSeverity from './Severity';
import { useSupabase } from "../contexts/Subabase";
import ReactTimeAgo from 'react-time-ago'
import PictureCarousel from './PictureCarousel';
import { Link } from 'react-router-dom';
import ComponentVote from '../pages/problem/ComponentVote';


import { useEffect, useState } from "react";
function ProblemCard({ problem }: { problem: ProblemCardInterface}) {



  const { supabase, session } = useSupabase();
  const [wheelImage, setWheelImage] = useState<string>('');

    useEffect(() => {
      if (problem.wheel.image === null) {
        setWheelImage('');
        return;
      }
        const { data } = supabase.storage.from('wheelwise').getPublicUrl(problem.wheel.image)
        setWheelImage( data.publicUrl )
      }, [problem.wheel.image, supabase.storage]);

  return (
    
    <div className='container mx-auto dark:text-gray-200 mt-4'>
      <hr className="border-gray-400" />
      <div className='text-sm flex flex-row mt-2'>
    
        <div className='basis-1/2 flex flex-row'>
          <div className='pr-2'>
          <img className="inline-block size-4 rounded-full" src={wheelImage} alt="Avatar" />
          </div>
          {problem.wheel.brand} {problem.wheel.name}
        </div>
        <div className='basis-1/2'>
          <p className='text-right relative mr-4 pt-1'> <ReactTimeAgo date={problem.created} locale="en-US"/> by {problem.user.name}</p>
        </div>
        <div className='basis'>
          { problem.user.id === session?.user?.id && (
          <Link to={`/problems/edit/${problem.id}`}>
          <button
            className="text-slate-800 hover:text-blue-600 text-sm bg-white hover:bg-slate-100 border border-slate-200 rounded p-1 inline-flex space-x-1 items-center">
            <span><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                    stroke="currentColor" className="w-4 h-4">
                    <path strokeLinecap="round" strokeLinejoin="round"
                        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                </svg>
            </span>
            <span className="hidden md:inline-block">Edit</span>
          </button>
          </Link>
          )}
        </div>
      </div>
      <Link to={`/problems/id/${problem.id}`} className='block'>
        <div>
          <h3 className='text-xl'>{problem.title}</h3>
          <PictureCarousel uploads={problem.uploads} />
          <p className='text-base'>{problem.description}</p>
        </div>
      </Link>
      <p>
        <ComponentVote total={problem.votes} id={problem.id} />
        <ComponentSeverity name={problem.severity.name} score={problem.severity.score} />
        {problem.component.map((component, index) => (
          <span 
          className='inline-flex ml-2 mt-2 items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium border border-blue-600 text-blue-600 dark:text-blue-500'
          key={index}>{component.name}</span>
        ))}
      </p>
      
    </div>
  );
}


export default ProblemCard;
