
import React from 'react';

import { useSupabase } from '../../contexts/Subabase';
import { Formik, Field, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';


export default function Profile() {
    const { supabase, session, displayName } = useSupabase();
    const [formSubmitted, setFormSubmitted] = React.useState(false);

    if (formSubmitted) {
        return (
            <div className="max-w-[50rem] w-full mx-auto flex flex-col bg-white border shadow-sm rounded-xl dark:bg-neutral-900 dark:border-neutral-700 dark:shadow-neutral-700/70">
            <div className="p-4 md:p-5">
                <p className="mt-1 text-gray-500 dark:text-neutral-400 text-center">
                Update will take effect after you sign out and sign back in.
                </p>
            </div>
            </div>
        );
    }

  if (!session) {
    return (
      <div className="max-w-[50rem] w-full mx-auto flex flex-col bg-white border shadow-sm rounded-xl dark:bg-neutral-900 dark:border-neutral-700 dark:shadow-neutral-700/70">
        <div className="p-4 md:p-5">
          <p className="mt-1 text-gray-500 dark:text-neutral-400 text-center">
            How do you want us to know who you are? you need to sign in first.
          </p>
        </div>
      </div>
    )
  }

    const validationSchema = Yup.object().shape({
        display_name: Yup.string()
            .required('Please enter a username')
            .test('unique-name', 'Username already taken by another account', async (value) => {
                if (!value) return true; // Skip validation if value is empty
                const { data, error } = await supabase
                    .from('profile')
                    .select('id')
                    .eq('display_name', value)
                    .limit(1);
                if (error) {
                    console.error('Error checking username:', error);
                    return false;
                }
                return !data || data.length === 0;
            }),
    });

  const SetDisplayName = async (name: string) => {
    const updateProfile = async (name: string) => {
        try {
            const { error } = await supabase
                .from('profile')
                .update({ display_name: name })
                .eq('id', session?.user.id);
            if (error) {
                throw new Error(error.message);
            }
            console.log('Profile updated successfully');
        } catch (error) {
            console.error('Error updating profile:', error);
        }
    };

    // Call the updateProfile function inside SetDisplayName
    await updateProfile(name);
    setFormSubmitted(true);
  };

  //senEmail();

  interface Values {
    display_name: string;
    email: string;
  }

  return (
    <div className="max-w-[50rem] w-full mx-auto flex flex-col bg-white border shadow-sm rounded-xl dark:bg-neutral-900 dark:border-neutral-700 dark:shadow-neutral-700/70">
        <div className="p-4 md:p-5">
      <Formik
        initialValues={{
          display_name: displayName ?? '',
          email: session?.user.email ?? 'toto',
        }}
        onSubmit={(
          values: Values,
          { setSubmitting, resetForm }: FormikHelpers<Values>
        ) => {
            SetDisplayName(values.display_name);
          setSubmitting(false);
          resetForm();
        }}
        validationSchema={validationSchema}
      >
        {({ errors, touched }) => (
        <Form>
        <div className="w-full space-y-3 ">
        <p className="mt-4 text-sm  text-gray-500 ms-3 dark:text-neutral-400">Email address</p>
            <Field type="input" disabled name="email" className=" border py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600" readOnly />
        </div>
        <p className="mt-4 text-sm  text-gray-500 ms-3 dark:text-neutral-400">Username</p>
          <Field as="input" name="display_name" className="py-3 mt-4 px-4 pe-9 block w-full border-gray-200 rounded-lg text-sm bg-white border focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600" />
          {errors.display_name && touched.display_name ? (
                  <p className="text-sm ml-2 text-red-500">{errors.display_name}</p>
                ) : null}
          <div className='flex justify-end'>
            <button type="submit" 
                className=" mt-4 py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
          >Update Profile</button>
          </div>        
        </Form>
        )}
      </Formik>
    </div></div>
  );
}