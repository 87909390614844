import React from 'react';


import { useSupabase } from '../../contexts/Subabase';
import { Formik, Field, Form, FormikHelpers } from 'formik';


function Contact() {

  const { supabase, session } = useSupabase();
  const [formSubmitted, setFormSubmitted] = React.useState(false);

  if (formSubmitted) {
    return (
      <div className="max-w-[50rem] w-full mx-auto flex flex-col bg-white border shadow-sm rounded-xl dark:bg-neutral-900 dark:border-neutral-700 dark:shadow-neutral-700/70">
        <div className="p-4 md:p-5">
          <p className="mt-1 text-gray-500 dark:text-neutral-400 text-center">
            Thank you for contacting us! We will get back to you soon.
          </p>
        </div>
      </div>
    );
  }

  if (!session) {
    return (
      <div className="max-w-[50rem] w-full mx-auto flex flex-col bg-white border shadow-sm rounded-xl dark:bg-neutral-900 dark:border-neutral-700 dark:shadow-neutral-700/70">
        <div className="p-4 md:p-5">
          <p className="mt-1 text-gray-500 dark:text-neutral-400 text-center">
            Please create an account or sign in to contact us.
          </p>
        </div>
      </div>
    )
  }


  const senEmail = async (subject: string, message: string) => {
    const { data, error } = await supabase.functions.invoke('email', {
      body: JSON.stringify({ subject: `Contact Page: ${subject}`, message: message })
    })

    if (error) {
      console.error('Error sending email:', error);
    } else {
      console.log('Email sent:', data)
    }
    setFormSubmitted(true);
  };

  //senEmail();

  interface Values {
    subject: string;
    message: string;
  }

  return (
    <>
    <div className="max-w-[50rem] w-full mx-auto flex flex-col bg-white border shadow-sm rounded-xl dark:bg-neutral-900 dark:border-neutral-700 dark:shadow-neutral-700/70">
        <div className="p-4 md:p-5">
      <Formik
        initialValues={{
          subject: 'A wheel is missing',
          message: '',
        }}
        onSubmit={(
          values: Values,
          { setSubmitting }: FormikHelpers<Values>
        ) => {
          senEmail(values.subject, values.message);
          setSubmitting(false);
        }}
      >
        
        <Form>
          <label htmlFor="subject">Why do you contact us?</label>
          <Field as="select" id='subject' name="subject" className="py-3 mt-4 px-4 pe-9 block w-full border-gray-200 rounded-lg text-sm bg-white border focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600">
            <option>A wheel is missing</option>
            <option>I want to suggest something</option>
            <option>I spot a bug</option>
            <option>Other</option>
          </Field>

          <Field as="textarea" id="message" name="message" className="py-3 px-4 block border mt-4 w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600" placeholder="Message" rows={5} />
          <div className='flex justify-end'>
          <button type="submit" className=" mt-4 py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
          >Submit</button>
          </div>        
        </Form>
      </Formik>
    </div></div>
    <div className="max-w-[50rem] mt-4 w-full mx-auto flex flex-row mb-4">
        <a href='https://gitlab.com/asfadev/wheelwise/-/boards' target='_blank' rel='noreferrer'>
        <button
          type="button"
          className="py-3 px-4 basis-1/4 h-full items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800"
        >
          <div className="flex items-center">
            <img src="/images/gitlab-logo.png" alt="Gitlab" className="h-6" />
            <span className="ml-2">See Roadmap</span>
          </div>
        </button>
        </a>
      </div>
    </>
  );
}

export default Contact;
