import { useState } from 'react';
import { useSupabase } from '../contexts/Subabase';


function CommentFormComponent({ problemId }: { problemId: string | undefined }) {
    const { supabase, session } = useSupabase();
    const [newComment, setNewComment] = useState<string>('');

   if(!session){
         return <></>
    }

    const handleCommentSubmit = async () => {
        if (!newComment.trim()) return;

        const { error } = await supabase
            .from('comment')
            .insert([{ comment: newComment, problem_id: problemId, created: new Date().toISOString(), user_id: session?.user.id }]);

        if (error) {
            console.error('Error saving comment: ' + error.message);
        } else {
            setNewComment('');
        }
    };

    return (
        <div className='mt-4 border border-gray-400 rounded-lg'>
            <textarea
                className="py-3 px-4 block w-full border-gray-400 rounded-lg text-sm focus:border-gray-500 focus:ring-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                rows={3}
                placeholder="Add a comment"
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
            ></textarea>
            <div className="flex justify-end">
                <button
                    className="m-2 py-2 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                    onClick={handleCommentSubmit}
                    disabled={!newComment.trim()}
                >
                    Comment
                </button>
            </div>
        </div>

    );
}
export default CommentFormComponent;