import {  QueryData } from '@supabase/supabase-js'
import { useSupabase } from '../contexts/Subabase';

import ProblemCardInterface from '../interfaces/ProblemCard';

interface Result {
    data: ProblemCardInterface[] | null;
    error: string | null;
}


async function GetProblems({
    limit,
    id,
    wheelSlug,
    supabase
  }: {
    limit: number;
    id?: string | null;
    wheelSlug?: string;
    supabase: ReturnType<typeof useSupabase>['supabase'];
  }): Promise<Result> {
    const problemsWithWheelsQuery = supabase.from('problem').select(`
        *, 
        wheel!inner(slug, name, image, brand!inner(name)), 
        severity!inner(name, score, id),
        profile!inner(id,  display_name),
        component (id,  name),
        problem_upload(*)
        `).limit(limit)
      .order('created', { ascending: false });

    if (id) {
        problemsWithWheelsQuery.eq('id', id);
    }

    if (wheelSlug) {
        problemsWithWheelsQuery.eq('wheel.slug', wheelSlug);
    }

    type ProblemWithWheels = QueryData<typeof problemsWithWheelsQuery>

    const { data, error } = await problemsWithWheelsQuery;
    const newProblems: ProblemCardInterface[] = [];

    if (error) {
      console.error('Error fetching problem:', error);
      return { data: null, error: error.message };
    } else {

        const problems: ProblemWithWheels = data;

        problems.forEach((problem) => {      
        const cardProblem: ProblemCardInterface = {
            id: problem.id,
            title: problem.title,
            description: problem.description,
            severity: {
              name: problem.severity.name,
              score: problem.severity.score,
              id: problem.severity.id,
            },
            created: problem.created ? new Date(problem.created) : new Date(),        
            votes: problem.votes ?? 0,
            component: problem.component,
            wheel: {
              name: problem.wheel.name,
              brand: problem.wheel.brand.name,
              image: problem.wheel.image,
              slug: problem.wheel.slug
            },
            user: {
              name: problem.profile.display_name ?? 'anon',
              id: problem.profile.id
            },
            uploads: problem.problem_upload
        };
        newProblems.push(cardProblem);
        });
        return { data: newProblems, error: null };
    }
    

}
export default GetProblems;