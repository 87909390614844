import React, { useCallback, useEffect, useState } from 'react';
import { useSupabase } from '../../contexts/Subabase';


interface VoteProps {
    total: number;
    id: string;
}
export default function ComponentVote({ total, id }: VoteProps) {

    const [votes, setVotes] = useState<number>(total);
    const [voted, setVoted] = useState<boolean>(false);
    const {session, supabase} = useSupabase()

    const handleVote = async () => {
        if (!session) {
            return
        }

        if (voted) {
            const { error } = await supabase.from('problem_vote').delete().eq('problem_id', id).eq('user_id', session.user.id);
            if (error) {
                console.error('Error voting:', error.message)
                return
            }

            setVotes(votes - 1);
            setVoted(false);
        }else{
            const { error } = await supabase.from('problem_vote').insert([{ problem_id: id, user_id: session.user.id }]);
            if (error) {
                console.error('Error voting:', error.message)
                return
            }
           
            setVotes(votes + 1);
            setVoted(true);
        }
    }

    const didVote = useCallback(async () => {
        if (!session) {
            return;
        }
        const { data, error } = await supabase
            .from('problem_vote')
            .select('*')
            .eq('problem_id', id)
            .eq('user_id', session.user.id);

        if (error) {
            console.error('Error checking vote:', error.message);
            return;
        }

        if (data && data.length > 0) {
            setVoted(true);
        } else {
            setVoted(false);
        }
    }, [id, session, supabase]);

    
    useEffect(() => {
        didVote();
    }, [didVote]);

    return (
        <button 
            type="button"
            onClick={handleVote}
            className="py-1.5 px-4 mr-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-full border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800">
            #MeeToo
            <span className="inline-flex items-center py-0.5 px-1.5 rounded-full text-xs font-medium bg-[#00899f] text-white">{votes}</span>
        </button>
    )
}